import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service';
import { EditUserinfoComponent } from './edit-userinfo/edit-userinfo.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../service/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {

  name;
  userId;
  email;
  // phoneNumber;
  password;
  editing: boolean = false;
  UserURL: string = environment.serverUrl + '/user-profile';
  userAttributes;
  userForm: FormGroup;
  userDetails;
  filledUserDetails;
  pageLoaded: boolean = false;
  selectedCountryCode;
  phoneNumber;
  invalidPhoneNumber: boolean = false;
  submitdisable:boolean = false;

  constructor(private authService: AuthService,
    private dialog: MatDialog,
    private http: HttpClient,
    private storageService: StorageService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    this.userId = this.storageService.get('username')
    let params = new HttpParams();
    params = params.append('userId', this.userId);
    this.http.get(this.UserURL, { params: params }).subscribe(res => {

      this.userDetails = res;
      this.email = this.userDetails.email;
      this.phoneNumber = this.userDetails.phone_number;
      this.pageLoaded = true;
    
    }
    )

    this.userForm = this.formBuilder.group({
      userId: [this.userId],
      email: ['', [Validators.required, Validators.email]],
      selectedCountryCode: [''],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]]
    });
  }

  saveUserDetails() {
this.submitdisable = true;
    this.filledUserDetails = Object.assign({}, this.userForm.value);
    this.userAttributes = {
      "userId": this.userId,
      "userAttributes": {
        "email": this.filledUserDetails.email,
        "phone_number": this.filledUserDetails.selectedCountryCode + this.filledUserDetails.phoneNumber
      }
    }

    this.http.put(this.UserURL, this.userAttributes).subscribe(
      res => {
      
        window.location.reload();
      })
  }



  openEditDialog() {
    const formdata = {
      username: this.name
    }
    const dialogRef = this.dialog.open(EditUserinfoComponent, {
      width: '500px',
      data: formdata
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the dialog if needed
      console.log('Dialog closed with result:', result);
    });
  }

  closeForm() {
    this.editing = false
  }

  toggleedit() {
    this.editing = true
  }

}
