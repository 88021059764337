// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
  font-weight: bold;
  background-color: #E6E8EB;
}

table {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}

table thead th {
  color: white;
  font-size: 8pt;
  background-color: #3b5998;
}

tr td {
  font-size: 8pt;
}

th, td {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Show ellipsis for truncated text */
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/qpcr-result-page/qpcr-pdf-layout/qpcr-pdf-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,yBAAA;AACJ;;AACE;EACE,WAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;;AAAE;EAAiB,YAAA;EAAc,cAAA;EAAe,yBAAA;AAMhD;;AALE;EACE,cAAA;AAQJ;;AANE;EACE,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA,EAAA,0BAAA;EACA,gBAAA,EAAA,6BAAA;EACA,uBAAA,EAAA,qCAAA;AASJ","sourcesContent":["th {\r\n    font-weight: bold;\r\n    background-color: #E6E8EB;\r\n  }\r\n  table {\r\n    width: 100%;\r\n    margin-bottom: 20px;\r\n    margin-top: 10px;\r\n  }\r\n  table thead th { color: white; font-size:8pt; background-color: #3b5998}\r\n  tr td{\r\n    font-size: 8pt;\r\n  }\r\n  th, td {\r\n    border: 1px solid #ddd;\r\n    padding: 0px;\r\n    text-align: center;\r\n    white-space: nowrap; /* Prevent text wrapping */\r\n    overflow:hidden; /* Hide overflowing content */\r\n    text-overflow: ellipsis; /* Show ellipsis for truncated text */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
