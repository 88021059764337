import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../service/storage.service';
import { environment } from '../../../../environments/environment';
import { AuthNewService } from '../../../service/auth-new.service';

@Component({
  selector: 'app-password-validity',
  templateUrl: './password-validity.component.html',
  styleUrls: ['./password-validity.component.scss']
})
export class PasswordValidityComponent implements OnInit {
  daysLeft: number;
  forcedLoginURL: string = environment.serverUrl + '/forced_login'
  customAttributes
  username
  password
  error = null;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authservice: AuthNewService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.daysLeft = 0;

    this.route.queryParams.subscribe(params => {
      this.daysLeft = params['daysLeft'];
     
    });
  }

  isLoading = false;


  async onContinue() {
    this.isLoading = true;
    this.customAttributes = {
      apiType: 'forced_login',
    };
 
    this.authservice.continueSignIn(this.customAttributes);
    this.isLoading = false;
    this.authservice.signinError.subscribe((error: any) => {
 
      if (error) {
        console.log(error)
        if (error instanceof Error && error.message.includes('You have crossed the signin attempts')) {
          this.error = 'Your account has been blocked. Please contact support.';
        }
        else {
          this.error = 'Username or Password is incorrect.';
        }

      } else {
        this.error = '';
      }

      // check for the error 
      if (this.error) {
        this.router.navigate(['/extra-layout/signin'], {
          queryParams: {
            error: this.error
          }
        });
      }
    })



    this.authservice.user.subscribe((user: any) => {
    
      if (user) {

        const storedData = this.storageService.get('access');

        if (storedData == 'QA') {
          this.router.navigate(['default-layout/user-details'])
        }
        else
          this.router.navigate(['default-layout/previous-analysis'])

      }
   
    })

  }

  onChangePassword() {
    this.router.navigate(['/extra-layout/forgot-password'], { queryParams: { passtype: 'Change' } });
  }

}
