// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-div {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.custom-table {
  width: 100%;
}

th {
  font-weight: bold;
  background-color: #E6E8EB;
}

table {
  width: 100%;
}

table thead th {
  color: white;
  font-size: 8pt;
  background-color: #3b5998;
}

tr td {
  font-size: 8pt;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: center;
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: hidden;
  /* Hide overflowing content */
  text-overflow: ellipsis;
  /* Show ellipsis for truncated text */
}

.icon-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.icon {
  flex: 0.5;
  margin-right: 2px;
  margin-top: 10px;
}

.comment-box {
  display: flex;
  flex-direction: column;
}

.selected-row {
  background-color: rgb(189, 187, 187);
  color: black; /* Optional: for better contrast */
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/qpcr-result-page/qpcr-result-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,iBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAKA;EACE,YAAA;EACA,cAAA;EACA,yBAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;;EAEE,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,0BAAA;EACA,gBAAA;EACA,6BAAA;EACA,uBAAA;EACA,qCAAA;AAFF;;AAKA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAFF;;AAKA;EACE,SAAA;EACA,iBAAA;EACA,gBAAA;AAFF;;AAMA;EACE,aAAA;EACA,sBAAA;AAHF;;AAOA;EACE,oCAAA;EACA,YAAA,EAAA,kCAAA;AAJF","sourcesContent":[".main-div {\r\n  height: 30vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.table-container {\r\n  overflow-x: auto;\r\n  max-width: 100%;\r\n\r\n}\r\n\r\n.custom-table {\r\n  width: 100%;\r\n}\r\n\r\nth {\r\n  font-weight: bold;\r\n  background-color: #E6E8EB;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n  // margin-bottom: 20px;\r\n  // margin-top: 10px;\r\n}\r\n\r\ntable thead th {\r\n  color: white;\r\n  font-size: 8pt;\r\n  background-color: #3b5998\r\n}\r\n\r\ntr td {\r\n  font-size: 8pt;\r\n}\r\n\r\nth,\r\ntd {\r\n  border: 1px solid #ddd;\r\n  padding: 0px;\r\n  text-align: center;\r\n  white-space: nowrap;\r\n  /* Prevent text wrapping */\r\n  overflow: hidden;\r\n  /* Hide overflowing content */\r\n  text-overflow: ellipsis;\r\n  /* Show ellipsis for truncated text */\r\n}\r\n\r\n.icon-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin-top: 5px;\r\n}\r\n\r\n.icon {\r\n  flex: 0.5;\r\n  margin-right: 2px;\r\n  margin-top: 10px;\r\n\r\n}\r\n\r\n.comment-box {\r\n  display: flex;\r\n  flex-direction: column;\r\n \r\n}\r\n\r\n.selected-row {\r\n  background-color: rgb(189, 187, 187);\r\n  color: black; /* Optional: for better contrast */\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
