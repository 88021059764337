// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .main-content {
  padding: 70px 0 0 220px;
  background-color: white;
  transition: padding 0.2s ease-in-out;
}
@media screen and (max-width: 2048px) {
  :host .main-content {
    padding-left: 0;
  }
}
:host navbar {
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out;
}
@media screen and (max-width: 2048px) {
  :host navbar {
    width: 100%;
  }
}
:host .open-sidebar .main-content {
  animation-duration: 0.2s;
  padding: 70px 0 0 220px;
}
:host .open-sidebar navbar {
  left: 220px;
}
:host .open-sidebar sidebar {
  left: 0;
}
@media screen and (max-width: 2048px) {
  :host .open-sidebar sidebar ::ng-deep .sidebar-overlay {
    opacity: 1;
    transition: 0.2s opacity 0s ease-in-out, 0s visibility 0s ease-in-out;
    visibility: visible;
  }
}
:host .rtl navbar {
  left: 0;
}
@media screen and (max-width: 2048px) {
  :host .rtl navbar {
    left: auto;
  }
}
:host .rtl.open-sidebar navbar {
  right: 220px;
}
:host .rtl.open-sidebar sidebar {
  left: auto;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/default/default.component.scss"],"names":[],"mappings":"AAGE;EACE,uBAAA;EACA,uBAAA;EACA,oCAAA;AAFJ;AAGI;EAJF;IAKI,eAAA;EAAJ;AACF;AAEE;EACE,yDACE;AADN;AAGM;EAJJ;IAKI,WAAA;EAAJ;AACF;AAGI;EACI,wBAAA;EACA,uBAAA;AADR;AAGI;EACE,WAAA;AADN;AAGI;EACE,OAAA;AADN;AAIQ;EADF;IAEI,UAAA;IACA,qEACE;IAEF,mBAAA;EAHR;AACF;AAQI;EACE,OAAA;AANN;AAOM;EAFF;IAGI,UAAA;EAJN;AACF;AAeM;EACE,YAAA;AAbR;AAeM;EACE,UAAA;EACA,QAAA;AAbR","sourcesContent":["@import '../../../assets/sass/variable';\r\n\r\n:host {\r\n  .main-content {\r\n    padding: 70px 0 0 $sidebar-width;\r\n    background-color: white;\r\n    transition: padding .2s $animation;\r\n    @media screen and (max-width: 2048px) {\r\n      padding-left: 0;\r\n    }\r\n  }\r\n  navbar {\r\n    transition:\r\n      left .2s $animation,\r\n      right .2s $animation;\r\n      @media screen and (max-width: 2048px) {\r\n      width: 100%;\r\n    }\r\n  }\r\n  .open-sidebar {\r\n    .main-content {\r\n        animation-duration: .2s;\r\n        padding: 70px 0 0 $sidebar-width;\r\n    }\r\n    navbar {\r\n      left: 220px;\r\n    }\r\n    sidebar {\r\n      left: 0;\r\n\r\n      ::ng-deep .sidebar-overlay {\r\n        @media screen and (max-width: 2048px) {\r\n          opacity: 1;\r\n          transition:\r\n            .2s opacity 0s $animation,\r\n            0s visibility 0s $animation;\r\n          visibility: visible;\r\n        }\r\n      }\r\n    }\r\n  }\r\n  .rtl {\r\n    navbar {\r\n      left: 0;\r\n      @media screen and (max-width: 2048px) {\r\n        left: auto;\r\n      }\r\n    }\r\n    // .main-content {\r\n    //   padding-left: 0;\r\n\r\n    //   padding-right: $sidebar-width;\r\n    //   @media #{$max2048} {\r\n    //     padding-right: 0;\r\n    //   }\r\n    // }\r\n    &.open-sidebar {\r\n      navbar {\r\n        right: 220px;\r\n      }\r\n      sidebar {\r\n        left: auto;\r\n        right: 0;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
