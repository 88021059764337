// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-validity-container {
  text-align: center;
  margin-top: 50px;
}

.buttons {
  margin-top: 20px;
}

.continue-button,
.change-password-button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
}

.continue-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.change-password-button {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.continue-button:hover,
.change-password-button:hover {
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/amplify-auth/password-validity/password-validity.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,gBAAA;AACJ;;AAEE;;EAEE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;AACJ;;AAEE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEE;;EAEE,YAAA;AACJ","sourcesContent":[".password-validity-container {\r\n    text-align: center;\r\n    margin-top: 50px;\r\n  }\r\n  \r\n  .buttons {\r\n    margin-top: 20px;\r\n  }\r\n  \r\n  .continue-button,\r\n  .change-password-button {\r\n    padding: 10px 20px;\r\n    margin: 0 10px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .continue-button {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    border: none;\r\n  }\r\n  \r\n  .change-password-button {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    border: none;\r\n  }\r\n  \r\n  .continue-button:hover,\r\n  .change-password-button:hover {\r\n    opacity: 0.8;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
