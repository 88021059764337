import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SharedService } from '../../../layouts/shared-service';
import { AnalysisDetails, AnalysisDetailsqPCR, Cq } from '../../../model/analysis-details.model';
import { DropDownService } from '../../../service/drop-down.service';
import { TemplateService } from '../../../service/template.service';
import { UploadService } from '../../../service/upload.service';
import { StorageService } from '../../../service/storage.service';
import { T } from '@angular/cdk/keycodes';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-qpcr-report',
  templateUrl: './qpcr-report.component.html',
  styleUrls: ['./qpcr-report.component.scss']
})
export class QpcrReportComponent implements OnChanges, OnInit {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';

  analysisFormGroup: FormGroup;
  maxDate;
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  post: any;
  tableParamValue = [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  NewSmparameterlist = [];
  Lmparameterlist = [];
  NewLmparameterlist = [];
  selectedValTables = [];
  selectedAssayTables = [];
  selectedGeneralTables = [];
  customdisable: boolean = true;
  disabledValue;
  Cq: boolean = false;
  BackCalculated: boolean = false;
  decimalNumberPattern = '^[0-9]+(\.[0-9]+)?$';
  submitDisabled: boolean = true;


  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private uploadService: UploadService,
    private dropDownService: DropDownService,
    private ref: ChangeDetectorRef,
    private storageService: StorageService,

  ) {
    this._sharedService.emitChange(this.pageTitle);
    uploadService.disabledValue.subscribe(res => {
      this.disabledValue = res;
    })

  }
  ngOnInit(): void {
    this.createForm();
    this.setupTargetTypeListeners();
    this.analysisFormGroup.valueChanges.subscribe(() => {
      this.updateButtonState();  // Update the submit button state
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('analysistype' in changes) {
      if (changes.analysistype.currentValue) {
        this.analysistype = changes.analysistype.currentValue;
      }
    }
  }


  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      analysisSubtype: [null],
      study: [null],
      TargetNames: this.formBuilder.array([this.addAnalyteRows()]),
      analyteNames: this.formBuilder.array([this.addAnalyteRows()]),
      files: [null],
      file_path: [null],
      analyteName: ['qPCR'],
      projectCode: [null],
      targetName: ['', [Validators.pattern('^[A-Za-z\x270-9 .,-_]+$')]],

    });


    this.ref.detectChanges();

    if (this.editvalues !== undefined) {

      const qPCRvalue: AnalysisDetailsqPCR = new AnalysisDetailsqPCR()
      qPCRvalue.analysisType = this.analysistype;
      qPCRvalue.analysisSubtype = this.editvalues.analysisSubtype;
      qPCRvalue.study = this.editvalues.study;
      qPCRvalue.projectCode = this.editvalues.projectCode;
      qPCRvalue.analyteNames = this.editvalues.analyteNames;
      qPCRvalue.TargetNames = this.editvalues.TargetNames || [];
      qPCRvalue.file_path = this.editvalues.file_path;
      qPCRvalue.files = this.editvalues.files;
      qPCRvalue.analyteName = this.editvalues.analyteName;
      qPCRvalue.targetName = this.editvalues.targetName;
      for (let i = 1; i < qPCRvalue.TargetNames.length; i++) {
        this.TargetNames.push(this.addAnalyteRows());
        this.setupTargetTypeListeners();
      }

      this.analysisFormGroup.patchValue(qPCRvalue);
      this.updateButtonState();
    }

  }

  atLeastOneCheckboxSelected() {
    return (group: FormGroup): { [key: string]: boolean } | null => {
      const cq = group.get('QT.displayCq')?.value;
      const backCalculated = group.get('QT.displayBackCalculated')?.value;
      if (!cq && !backCalculated) {
        return { 'atLeastOneCheckboxRequired': true };
      }
      return null;
    }

  }

  updateSpikeValidity(controlIndex: number, controlName: string) {
    this.analysisFormGroup.controls.TargetNames['controls'].forEach((analyte: FormGroup) => {
      const spikeSamplesControl = analyte.get('QT.SpikeSamples');
      const recoveryThresholdControl = analyte.get('QT.recoveryThreshold');
      const SpikeCopiesControl = analyte.get('QT.SpikeCopies');
      const inhibitorsSamplesControl = analyte.get('QT.inhibitorsSamples');
      const InhSpikeCopiesControl = analyte.get("QT.InhSpikeCopies")

      if (spikeSamplesControl) {
        spikeSamplesControl.valueChanges.subscribe((spikeValue: boolean) => {
     
          if (!spikeValue) {
            recoveryThresholdControl?.reset();
            recoveryThresholdControl?.setErrors(null);
            SpikeCopiesControl?.reset();
            SpikeCopiesControl?.setErrors(null);
          }
        });
        inhibitorsSamplesControl.valueChanges.subscribe((inhibitorValue: boolean) => {
       
         if(!inhibitorValue){
          InhSpikeCopiesControl?.reset();
          InhSpikeCopiesControl?.setErrors(null);
         }
        });
      }

    });

  }

  updateValidity(controlIndex: number, controlName: string) {
    const targetControl = this.analysisFormGroup.get(`TargetNames.${controlIndex}`); // Access by index
    if (targetControl) {
      const displayCqControl = targetControl.get('QT.displayCq'); // Assuming nested under QT
      const intraPrecControl = targetControl.get('QT.Cq.Intra_prec_CV_Cq');

      if (displayCqControl && displayCqControl.value === false && intraPrecControl) {
        intraPrecControl.reset();
        intraPrecControl.markAsPristine();
        intraPrecControl.clearValidators();
        intraPrecControl.updateValueAndValidity();
      }

      if (displayCqControl?.value === false && intraPrecControl?.value === null) {
        intraPrecControl?.clearValidators();
        intraPrecControl?.updateValueAndValidity();
      } else {
        targetControl.setValidators(this.atLeastOneCheckboxSelected());
      }

      intraPrecControl?.updateValueAndValidity();
      targetControl.updateValueAndValidity();


    }
  }

  updateValidityBackCalc(controlIndex: number, controlName: string) {
    const targetControl = this.analysisFormGroup.get(`TargetNames.${controlIndex}`); // Access by index
    if (targetControl) {
      const displayBackCalcControl = targetControl.get('QT.displayBackCalculated');
      const genIntraPrecControl = targetControl.get('QT.BackCalculated.Gen_Intra_prec_CV_Back_Calc');
      const lloqUloqIntraPrecControl = targetControl.get('QT.BackCalculated.LLOQ_ULOQ_Intra_prec_CV_Back_Calc');

      if (displayBackCalcControl && displayBackCalcControl.value === false) {
        if (genIntraPrecControl) {
          genIntraPrecControl.reset();
          genIntraPrecControl.markAsPristine();
          genIntraPrecControl.clearValidators();
          genIntraPrecControl.updateValueAndValidity();
        }
        if (lloqUloqIntraPrecControl) {
          lloqUloqIntraPrecControl.reset();
          lloqUloqIntraPrecControl.markAsPristine();
          lloqUloqIntraPrecControl.clearValidators();
          lloqUloqIntraPrecControl.updateValueAndValidity();
        }
      }

      if (displayBackCalcControl?.value === false && genIntraPrecControl?.value == null && lloqUloqIntraPrecControl?.value == null) {
        genIntraPrecControl?.clearValidators();
        lloqUloqIntraPrecControl?.clearValidators();
      } else {
        targetControl.setValidators(this.atLeastOneCheckboxSelected());
      }
      genIntraPrecControl?.updateValueAndValidity();
      lloqUloqIntraPrecControl?.updateValueAndValidity();
      targetControl.updateValueAndValidity();
    }
  }


  get formControls() { return this.analysisFormGroup.controls; }

  onSubmit(post: any) {

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    this.analysisDetails.userId = this.storageService.get('username');
    this.analysisDetails.analysisId = (new Date).getTime().toString();
    this.analysisDetails.targetName = this.TargetNames.value.map(res =>
      res = res.targetName)
    this.post = post;

    this.formvalue.emit(this.analysisDetails);
  }

  addAnalyteRows() {
    return this.formBuilder.group({
      targetName: ['', [Validators.pattern('^[A-Za-z\x270-9 .,-_]+$')]],
      TargetType: [''],
      QL: this.formBuilder.group({
        QLRTsamples: [null],
        CVcriteria: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)])
      }),
      QT: this.formBuilder.group({
        QTRTsamples: [null],
        SpikeSamples: [null],
        inhibitorsSamples: [null],
        SpikeCopies: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        InhSpikeCopies: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        recoveryThreshold: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        LLOQ_ULOQ_Inter_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        Gen_Inter_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        Gen_Intra_Inter_Acc_RE_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        ULOQ_LLOQ_Inter_Intra_RE_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        Lower_Slope_Value: this.formBuilder.control(-3.6, [Validators.pattern('^[-]?[0-9]+\.[0-9]+$')]),
        Higher_Slope_Value: this.formBuilder.control(-3.1, [Validators.pattern('^[-]?[0-9]+\.[0-9]+$')]),
        displayCq: [false],
        displayBackCalculated: [false],
        validators: this.atLeastOneCheckboxSelected(),
        BackCalculated: this.formBuilder.group({
          Gen_Intra_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
          LLOQ_ULOQ_Intra_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        }),
        Cq: this.formBuilder.group({
          Intra_prec_CV_Cq: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        }),
        QualityControls: this.formBuilder.group({
          LLOQ_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
          Low_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
          Mid_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
          High_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
          ULOQ_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        })
      })
    })
  }


  setupTargetTypeListeners(): void {
    this.TargetNames.controls.forEach((control, index) => {
      const targetTypeControl = control.get('TargetType');
      targetTypeControl?.valueChanges.subscribe((newTargetType) => {
        if (newTargetType === 'QT') {
          const qlGroup = this.TargetNames.at(index).get('QL') as FormGroup;
          if (qlGroup) {
            qlGroup.markAsPristine();
            qlGroup.clearValidators();
            qlGroup.updateValueAndValidity();
          }
        }
        else if (newTargetType === 'QL') {
          const qtGroup = control.get('QT') as FormGroup;
          if (qtGroup) {
            qtGroup.clearValidators();
            qtGroup.setErrors(null);
            Object.keys(qtGroup.controls).forEach(key => {
              const qtControl = qtGroup.get(key);
              if (qtControl) {
                qtControl.clearValidators();
                qtControl.setErrors(null);
                qtControl.updateValueAndValidity();
              }
            });

            const qualityControlsGroup = qtGroup.get('QualityControls') as FormGroup;
            if (qualityControlsGroup) {
              Object.keys(qualityControlsGroup.controls).forEach(key => {
                const qcControl = qualityControlsGroup.get(key);
                if (qcControl) {
                  qcControl.clearValidators();
                  qcControl.setErrors(null);
                  qcControl.updateValueAndValidity();
                }
              });
            }
            qtGroup.updateValueAndValidity();
          }
          this.TargetNames.at(index).updateValueAndValidity();
          this.analysisFormGroup.updateValueAndValidity();
        }
      });

    });
  }


  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get TargetNames() {
    return this.analysisFormGroup.get('TargetNames') as FormArray;
  }

  addAnalyte() {
    this.TargetNames.push(this.addAnalyteRows());
  }

  removeAnalyte(index) {
    this.TargetNames.removeAt(index);
  }

  updateButtonState(): void {
    const isInvalid = this.isSubmitDisabled();
    const isFormValid = this.analysisFormGroup.valid;
    this.submitDisabled = isInvalid || !isFormValid;
  }


  isSubmitDisabled(): boolean {
    const targetNamesArray = this.analysisFormGroup.get('TargetNames') as FormArray;
    const isInvalid = targetNamesArray.controls.some((target: AbstractControl) => {
      const targetType = target.get('TargetType').value;

      if (targetType === 'QT') {
        const QT = target.get('QT') as FormGroup;
        const displayCq = QT.get('displayCq').value;
        const displayBackCalculated = QT.get('displayBackCalculated').value;
        return (QT.get('QTRTsamples').value === null)||(QT.get('SpikeSamples').value === null) || (QT.get('inhibitorsSamples').value === null) || (QT.get('SpikeSamples').value === true && !QT.get('SpikeCopies').value) || (QT.get('SpikeSamples').value === true && !QT.get('recoveryThreshold').value)|| (QT.get('inhibitorsSamples').value === true && !QT.get('InhSpikeCopies').value) ||
        (displayCq === false && displayBackCalculated === false) ||
          (displayCq === true && !QT.get('Cq.Intra_prec_CV_Cq').value) ||
          (displayBackCalculated === true && !QT.get('BackCalculated.LLOQ_ULOQ_Intra_prec_CV_Back_Calc') && !QT.get('BackCalculated.Gen_Intra_prec_CV_Back_Calc'));
      } else if (targetType === 'QL') {
        const QL = target.get('QL') as FormGroup;   
        const isQLFieldsInvalid =
          (QL.get('QLRTsamples').value === null)  ||
          !QL.get('CVcriteria').value || !!QL.get('CVcriteria').errors;

        return isQLFieldsInvalid;
      }
    });
    return isInvalid;
  }


}