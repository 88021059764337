export const environment = {
    production: false,
    serverUrl: 'https://t1ll7hqze1.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'kriyaqpcrtest-test-upload',
    userPool: {
      UserPoolId: 'us-east-2_N9u8lhukL',
      ClientId: '211k4gjq9k6ebchb9t6vbgk4rs',
      region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:1bf950c5-fe3e-49be-82a4-ffd5f469953c',
    buckets: {
      'us-east-2': 'kriyaqpcrtest-test-upload'
    },
    modules: {
      key: 'analysisType',
      values: [
          {
            'name': 'qPCR',
            'desc': 'qPCR'
          }
      ]
  },
    defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:1bf950c5-fe3e-49be-82a4-ffd5f469953c', // REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_N9u8lhukL', // OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '211k4gjq9k6ebchb9t6vbgk4rs', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        region: 'us-east-2', // OPTIONAL -  Amazon service region
        bucket: 'kriyaqpcrtest-test-upload',
      }
    }
  };
  const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_N9u8lhukL',
    'aws_user_pools_web_client_id': '211k4gjq9k6ebchb9t6vbgk4rs',
    'aws_cognito_mfa_configuration': 'OPTIONAL',
  
  };
  export default awsconfig;
  