// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-link {
  float: right;
  color: #5DADE0;
  /* Change color as needed */
  cursor: pointer;
  /* Show pointer cursor on hover */
}

.edit-link:hover {
  color: darkblue;
  /* Change color on hover if desired */
  text-decoration: none;
  /* Remove underline on hover */
}

.input-group {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px; /* Adjust the gap between elements as needed */
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/user-page/user-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,cAAA;EACA,2BAAA;EACA,eAAA;EACA,iCAAA;AACF;;AAEA;EACE,eAAA;EACA,qCAAA;EACA,qBAAA;EACA,8BAAA;AACF;;AAKA;EACE,aAAA;EACA,gCAAA;EACA,SAAA,EAAA,8CAAA;EACA,mBAAA;AAFF","sourcesContent":[".edit-link {\r\n  float: right;\r\n  color: #5DADE0;\r\n  /* Change color as needed */\r\n  cursor: pointer;\r\n  /* Show pointer cursor on hover */\r\n}\r\n\r\n.edit-link:hover {\r\n  color: darkblue;\r\n  /* Change color on hover if desired */\r\n  text-decoration: none;\r\n  /* Remove underline on hover */\r\n}\r\n\r\n\r\n\r\n\r\n.input-group {\r\n  display: grid;\r\n  grid-template-columns: auto auto;\r\n  gap: 10px; /* Adjust the gap between elements as needed */\r\n  align-items: center;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
