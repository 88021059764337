import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TemplateService } from '../../service/template.service';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { Templates, TemplateDetail } from '../../model/analysis-details.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { error } from 'console';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  title = 'Please wait';
  public form: FormGroup;
  unsubscribe: any;
  public tableNames: any[];
  selected = '';
  selectedType;
  selectedDesc;
  fields = [];
  flag = false;
  selected1 = ''
  templateData: Templates[];
  enabled: boolean;
  columnNames: any[] = []
  jsonUrl = 'assets/data/column_data.json'

  selectedColumns: { [key: string]: string } = {};
  selectedOption: string = '';
  filteredOptions: string[];
  userInputs: { [key: string]: string } = {};
  disabledValue: boolean = true;
  submitModalResult: boolean = false;
  showSuccessMessage: boolean = false;
  showErrorMessage= null;
  showError = null;
  isFetching = true;

  constructor(private templateService: TemplateService,
    private loader: AppLoaderService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private http: HttpClient) {


  }

  openDialog() {
    this.loader.open(this.title);
    setTimeout(() => {
      this.loader.close();
    }, 10000)
  }

  ngOnInit() {

    const analysis_type = this.route.snapshot.paramMap.get('a_type');
    const analysis_subtype = this.route.snapshot.paramMap.get('a_subtype');

    // if (analysis_type && analysis_subtype !== null) {
    //   const name = null;
    // this.templateService.getTableData(name, analysis_type, analysis_subtype).subscribe((table_names: any[]) => {
    //   this.tableNames = table_names;

    // });
    // } else {
    this.templateService.getTableData().subscribe(
      (res: any) => {
      this.isFetching=false;
      this.columnNames = res.data;
      this.columnNames.forEach(item => {
        this.userInputs[item.col_key] = item.possible_names.join(', ');
      });
   },
   (error)=>{
    console.log(error);
    this.isFetching = false;
    this.showError = error.error.message;
    if(!error.error.message){
      this.showError=" Internal Server Error. Please contact Support Team."
    }

   }
  );

    // }
  }

  closeDialog() {
    this.loader.close();
  }

  tableBackCall() {
    const columns = [];
    // this.templateService.getTableData(this.selected).subscribe((t_data: any[]) => {
    //   columns.push(t_data[0].columns);
    //   this.fields = columns;
    //   this.selectedType = t_data[0].type
    //   this.selectedDesc = t_data[0].description
    //   this.flag = true
    //   this.form = new FormGroup({
    //     fields: new FormControl(JSON.stringify(columns[0]))
    //   })
    //   this.enabled = true;
    // });
  }

  tableValue(val) {
    this.selected = val;
    this.enabled = false;
    this.changeDetector.detectChanges();
    this.tableBackCall()
  }


  submittedData(event) {
    this.templateService.sendTableData(this.selectedType, this.selectedDesc, event)
      .subscribe((res) => {
        this.toastr.success('Your data have been saved successfully.');
      }, (error) => {
        this.toastr.error('Error while saving your data.')
      });
  }
  onInputChange() {
    this.disabledValue = !Object.values(this.userInputs).some(value => value && value.trim().length > 0);
  }


  AddedColumnValues() {
    this.showErrorMessage = null
    this.disabledValue = true;
    this.submitModalResult = true
    const transformedData = {
      key: "post",
      data: this.columnNames.map(item => ({
        col_key: item.col_key,
        new_cols: this.userInputs[item.col_key].split(',').map(name => name.trim())
      }))
    };
    this.templateService.sendColumnData(transformedData).subscribe(
      (response: any[]) => {
        this.submitModalResult = false;
        this.showSuccessMessage = true;

      },
      (error) => {
        console.log(error);
        this.submitModalResult = false;
        // this.showErrorMessage = true;
        this.showErrorMessage = error.error.message;
        if(!error.error.message){
          this.showErrorMessage=" Unable to submit Request. Please contact Support Team."
        }
      }
    )
  }

}
