import { ChangeDetectorRef, Component, OnInit, OnDestroy, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DownloadFile, SearchResult } from '../../../model/analysis-details.model';
import { DBService, DownLoadService } from '../../../service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { User } from '../../../model/types';
import { SharedService } from '../../../layouts/shared-service';
import { Subscription } from 'rxjs/Subscription';
import { AuthNewService } from '../../../service/auth-new.service';
import { catchError, delay, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../../../service/storage.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import jsPDF from 'jspdf';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
import { DatePipe } from '@angular/common';
import * as Plotly from 'plotly.js-dist';
import { std, mean } from 'mathjs';
import { DropDownService } from '../../../service/drop-down.service';
import { interval, Subject } from 'rxjs';


@Component({
    selector: 'app-qpcr-result-page',
    templateUrl: './qpcr-result-page.component.html',
    styleUrls: ['./qpcr-result-page.component.scss']
})
export class QpcrResultPageComponent implements OnInit {
    maxDate;
    pageTitle = 'Analyze Report/Data';
    searchResult: SearchResult = <SearchResult>{};
    searchResultData: boolean = true;
    isFetching: boolean = true;
    signedInUser: any;
    currentUser: User;
    illegalUser: boolean;
    usr: any;
    analysisId = '';
    navigationSubscription: Subscription;
    qpcrTables = []
    step = 0;
    urlPath: string;
    signedInUserAccess;
    errorResult: string;
    private singleSearchData: Subscription;
    private currentLoginUser: Subscription;
    private PostEventData: Subscription;
    private getEventData: Subscription;
    EventDataUrl: string = environment.serverUrl + '/event-data';
    AnalysisDataURL: string = environment.serverUrl + '/analysis-data';
    DownloadFileobject: DownloadFile = <DownloadFile>{};
    AnalysisViewType: string;
    QpcrPdfData
    AuditPdfData
    @ViewChild('htmlData', { static: false }) htmlData!: ElementRef;
    @ViewChild('auditData', { static: false }) auditData!: ElementRef;
    @ViewChild('plot', { static: false }) plotElement!: ElementRef;
    graphdata;
    graphdataKeys: string[];
    selectedRows: number[] = [];
    slope
    intercept
    disableSubmit: boolean = true
    executionId: string;
    logo: string;
    checkboxSelected: boolean = false;
    commentText: { run_id: string, selected_samples: string[], comments: string[] }[] = [];
    submitResultLoader: boolean = false
    submitEnabled: boolean = true;
    mode: string;
    graphHistoryData;
    graphHistoryDataKeys: string[];
    initialSampleNames = [];
    calc_CV = [];
    RE = [];
    selectedSampleNames: { run_id: string, selected_samples: string[] }[] = [];
    selectedComments: string[] = [];
    targetInfoList: { name: string, type: string }[] = [];
    analysisStatusText: string = 'Gathering Information ';
    private stopPolling = new Subject<void>();
    private pollingSubscription: Subscription;
    userGroup;
    userOrganization;

    constructor(
        private http: HttpClient,
        private dbService: DBService,
        private activatedroute: ActivatedRoute,
        private AuthService: AuthNewService,
        private router: Router,
        private _sharedService: SharedService,
        private downloadService: DownLoadService,
        private dropDownService: DropDownService,
        public datepipe: DatePipe,

    ) {

        this._sharedService.emitChange(this.pageTitle);
    }

    async ngOnInit() {
        this.pollForResult();
        this.AnalysisViewType = this.activatedroute.snapshot.paramMap.get('type');
        try {

            const data = await this.dbService.singleSearch.pipe(take(1)).toPromise();
            if (Object.keys(data).length === 0) {
                this.searchResultData = false;

            } else {
                this.isFetching = false;
            }
            data && this.populateResultData(data);
            this.loadImageAsBase64('/assets/img/App-Biodata.png');

        } catch (error) {
            console.error('Error occurred:', error);
        }
        this.populateTargetInfo();

    }

    loadImageAsBase64(url: string) {
        this.http.get(url, { responseType: 'blob' }).subscribe((blob: Blob) => {
            const reader = new FileReader();
            reader.onload = () => {
                this.logo = reader.result as string;
            };
            reader.readAsDataURL(blob);
        });
    }

    objectKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    populateResultData(data: SearchResult) {
        this.searchResult = data;
        this.mode = this.searchResult.analysisSubtype;
        this.errorResult = this.searchResult.error_message;
        this.QpcrPdfData = this.searchResult.pdf_data;
        this.graphdata = this.searchResult.GraphData;
        this.graphdata = JSON.parse(this.graphdata?.replace(/NaN/g, null));
        this.graphdataKeys = Object.keys(this.graphdata)
        this.graphHistoryData = this.searchResult.initial_graph_data;
        let output_file = this.searchResult.output_result_path_list.map(path => this.getSingedURL(path));
        this.searchResult.output_file_path = output_file;

        if (this.graphdata && Object.keys(this.graphdata).length > 0) {
            Object.keys(this.graphdata).forEach((targetName) => {
                const targetArray = this.graphdata[targetName];
                targetArray.forEach(dataObj => {
                    this.initializeComments(dataObj);
                });
                targetArray.forEach((dataObj, index) => {
                    setTimeout(() => {
                        this.CreateUpdatePlot(dataObj, index, targetName);
                        let comment = [];
                        let samples = [];
                        if (dataObj.comment && dataObj.comment.length > 0) {
                            dataObj.comment.forEach((res) => {
                                comment.push(res.comment);
                                samples.push(res.sample_id);
                            });
                            this.commentText.push({ run_id: dataObj.run_id, selected_samples: samples, comments: comment });
                            this.selectedSampleNames.push({ run_id: dataObj.run_id, selected_samples: samples });
                        }
                    }, 1000);
                });
            });
        }

    }


    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }


    initializeComments(dataObj: any) {
        if (!dataObj.comments) {
            dataObj.comments = new Array(dataObj.sample_name.length).fill('');
        }

        if (dataObj.comment) {
            dataObj.comment.forEach(backendComment => {
                const sampleIndex = dataObj.sample_name.indexOf(backendComment.sample_id);
                if (sampleIndex !== -1) {
                    dataObj.comments[sampleIndex] = backendComment.comment;
                }
            });
        }

    }

    exportPDF() {
        let maxDate = new Date();
        const maxUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.htmlData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);

        const documentDefinition = {
            content: [

                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',

                }, {
                    text: maxUTCDate,
                    width: 35,
                    style: 'date',
                    bold: true,
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread Analysis Date(UTC): ', bold: true }, this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ('RedThread User Uploaded File(s) Report'),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right'
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread User Uploaded File(s).pdf`);
    }

    exportAuditPDF() {
        let maxDate = new Date();
        const maxAuditUTCDate = this.datepipe.transform((maxDate), 'yyyy-MM-dd HH:mm:ss', 'UTC');
        const doc = new jsPDF();
        const pdfTable = this.auditData?.nativeElement;
        let html = htmlToPdfmake(pdfTable.innerHTML);
        const documentDefinition = {
            content: [
                {
                    image: this.logo,
                    width: 150,
                    style: 'logo',
                    margin: [0, 10, 10, 10],
                    fontSize: 10,
                },
                {
                    text: maxAuditUTCDate,
                    width: 35,
                    style: 'date',
                    fontSize: 10,
                },
                {
                    text: [{ text: 'Project Code: ', bold: true }, this.searchResult.projectCode],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 20, 10, 0],
                    fontSize: 10,

                },
                {
                    text: [{ text: 'Target Name: ', bold: true }, this.searchResult.targetName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread User Name: ', bold: true }, this.searchResult.userName],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: [{ text: 'RedThread Analysis Date(UTC): ', bold: true }, this.searchResult.analysis_date],
                    width: 20,
                    alignment: 'left',
                    margin: [0, 5, 5, 0],
                    fontSize: 10,
                },
                {
                    text: ("AUDIT REPORT"),
                    width: 100,
                    bold: true,
                    alignment: 'center',
                    margin: [10, 20, 10, 10],
                    decoration: 'underline',
                    fontSize: 15,
                },
                html
            ],
            styles: {
                logo: {
                    alignment: 'left',
                },
                date: {
                    alignment: 'right',
                }
            }

        };
        pdfMake.createPdf(documentDefinition).download(`RedThread Audit.pdf`);

    }

    getSingedURL(path: string, fileName = '') {
        if (path === undefined) {
            return ' ';
        }
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path, fileName);
        } else {
            return ' ';
        }

    }

    populateTargetInfo() {
        if (this.searchResult?.output_result_path_list) {
            this.targetInfoList = this.searchResult?.output_result_path_list.map(path => this.getTargetInfo(path));
        }
    }

    getTargetInfo(path: string): { name: string, type: string } {
        // Extract the part after 'Report/' and before '-output.xlsx'
        const regex = /Report\/(.+?)-(.+?)-output\.xlsx/;
        const match = path.match(regex);
        if (match && match.length >= 3) {
            return {
                name: match[1], // Target name
                type: match[2], // Target type
            };
        }
        return { name: 'Unknown', type: 'Unknown' }; // Fallback if not matched
    }

    async getOutputFilePath() {
        return this.searchResult.output_file_path;
    }


    pollForResult() {
        this.AuthService.getCurrentUser();
        this.currentLoginUser = this.AuthService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            this.userGroup = this.signedInUser.attributes["custom:group"];
            this.userOrganization = this.signedInUser.attributes["custom:organization"]
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.signedInUserAccess = this.signedInUser.attributes["custom:access"]
                this.activatedroute.paramMap.subscribe((params) => {
                    if (this.signedInUserAccess == 'SU' || this.signedInUserAccess == 'GA' || this.signedInUserAccess == 'QA' || this.signedInUserAccess == 'PI') {

                        this.usr = params.get('user');
                        this.analysisId = params.get('id');                      
                        // this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;


                        if (this.AnalysisViewType === 'previous') {
                            this.analysisStatusText = 'Generating Result ';
                            this.dbService.getAnalysisDetails(this.usr, this.analysisId);

                            this.singleSearchData = this.dbService.singleSearch.subscribe(
                                (data: SearchResult) => {

                                    if (Object.keys(data).length === 0) {
                                        this.searchResultData = false;
                                    } else {
                                        this.isFetching = false;
                                    }
                                    data && this.populateResultData(data);
                                }
                            );

                        }
                        else {
                            if (this.pollingSubscription) {
                                this.pollingSubscription.unsubscribe();
                            }
                            this.pollingSubscription = interval(5000).pipe(
                                switchMap(() => this.dbService.getAnalysisStatus(this.usr, this.analysisId, this.userOrganization, this.userGroup)),
                                takeUntil(this.stopPolling),
                                catchError((error) => {
                                    console.error('Polling error:', error);
                                    throw error;
                                })
                            ).subscribe(
                                (data) => {
                                    const dataStatus = data.data;

                                    if (dataStatus['analysis_status'] === 'Complete') {

                                        this.analysisStatusText = 'Generating Result '
                                        this.stopPolling.next();

                                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);

                                        this.singleSearchData = this.dbService.singleSearch.subscribe(
                                            (data: SearchResult) => {

                                                if (Object.keys(data).length === 0) {
                                                    this.searchResultData = false;
                                                } else {
                                                    this.isFetching = false;
                                                }
                                                data && this.populateResultData(data);
                                            }
                                        );
                                    }
                                    else {
                                        this.analysisStatusText = 'Gathering Information '
                                    }
                                },
                                (error) => {
                                    console.error('Error during polling:', error);
                                }
                            );
                        }



                    }

                });
            }

        })
    }

    setStep(index: number) {
        this.step = index;
    }

    toggleSelection(event: any, dataObj, index: number, idx, targetName) {
        dataObj.flag[index] = !dataObj.flag[index];
        const sampleName = dataObj.sample_name[index];
        const runId = dataObj.run_id;


        if (dataObj.flag[index]) {
            let runEntry = this.selectedSampleNames.find(entry => entry.run_id === runId);
            if (!runEntry) {
                runEntry = { run_id: runId, selected_samples: [] };
                this.selectedSampleNames.push(runEntry);
            }

            if (!runEntry.selected_samples.includes(sampleName)) {
                runEntry.selected_samples.push(sampleName);
                this.selectedComments.push(dataObj.comments[index] || '');
            }
        } else {
            const runEntry = this.selectedSampleNames.find(entry => entry.run_id === runId);
            const sampleIndex = runEntry.selected_samples.indexOf(sampleName);
            if (runEntry) {

                if (sampleIndex !== -1) {
                    runEntry.selected_samples.splice(sampleIndex, 1);
                    this.selectedComments.splice(sampleIndex, 1);

                    // If there are no more selected samples for this run_id, remove the entry
                    if (runEntry.selected_samples.length === 0) {
                        this.selectedSampleNames = this.selectedSampleNames.filter(entry => entry.run_id !== runId);
                    }
                }
            }

            let runIndex = this.commentText.findIndex(comment => comment.run_id === dataObj.run_id);
            if (runIndex !== -1) {
                this.commentText[runIndex].selected_samples = this.commentText[runIndex].selected_samples.filter(name => name !== sampleName);
                this.commentText[runIndex].comments = this.commentText[runIndex].comments.filter((_, i) => i !== sampleIndex);

                // If there are no more selected samples, remove the entry
                if (this.commentText[runIndex].selected_samples.length === 0) {
                    this.commentText.splice(runIndex, 1);
                }
            }
        }

        this.CreateUpdatePlot(dataObj, idx, targetName);

    }

    hasSelectedCheckboxes(dataObj: any): boolean {
        return dataObj.flag && dataObj.flag.some(flag => flag === true);
    }


    CreateUpdatePlot(dataObj: any, index, targetName) {
        this.updateSubmitEnabled();
        const selectedIndices = dataObj?.flag.map((flag, index) => flag && !this.selectedRows.includes(index));
        const selectedXValues = selectedIndices.map((flag, index) => flag ? dataObj.quantity_log10[index] : null).filter(val => val !== null);
        const selectedYValues = selectedIndices.map((flag, index) => flag ? dataObj.cq_mean[index] : null).filter(val => val !== null);
        const newselectedSampleNames = selectedIndices.map((flag, index) => flag ? dataObj.sample_name[index] : null).filter(val => val !== null);
        let filteredQuantity = dataObj?.quantity_log10.filter((_, index) => !selectedIndices[index]);
        let filteredCqMean = dataObj?.cq_mean.filter((_, index) => !selectedIndices[index]);
        let SingleCq = dataObj.single_cq;

        const filteredData = filteredQuantity
            .map((q, index) => ({ q, cqMean: filteredCqMean[index], index })) // Map to a combined structure
            .filter(item => item.q !== null && item.cqMean !== null);
        filteredQuantity = filteredData.map(item => item.q);
        filteredCqMean = filteredData.map(item => item.cqMean);
        this.searchResult.removed_sample_id[dataObj.run_id] = newselectedSampleNames;
        this.searchResult.removed_sample_id = Object.assign({}, this.searchResult.removed_sample_id);
        this.initialSampleNames = dataObj.sample_name;
        this.initialSampleNames = this.initialSampleNames.filter(res => !newselectedSampleNames.includes(res));

        const quantity_log10 = dataObj.quantity_log10.map(num => Number(num?.toFixed(3)));
        this.graphdata[targetName][index]['up_quantity_log10'] = quantity_log10;
        const cq_mean = dataObj.cq_mean.map(num => Number(num?.toFixed(3)));
        this.graphdata[targetName][index]['up_cq_mean'] = cq_mean;

        // Calculate linear regression
        const regression = this.calculateLinearRegression(filteredQuantity, filteredCqMean, index, SingleCq, targetName);


        const xAxisRange = [Math.min(...dataObj.quantity_log10), Math.max(...dataObj.quantity_log10)];
        const yAxisRange = [Math.min(...dataObj.cq_mean), Math.max(...dataObj.cq_mean)];

        // Add margin of 5 to axis range
        const xMargin = (xAxisRange[1] - xAxisRange[0]) * 0.05;
        const yMargin = (yAxisRange[1] - yAxisRange[0]) * 0.05;

        const data = [
            {
                x: filteredQuantity,
                y: filteredCqMean,
                mode: 'markers',
                type: 'scatter',
                name: 'Data',
                text: this.initialSampleNames,
                textposition: 'top center',
                marker: {
                    color: '#000000',
                    size: 10,
                    symbol: 'circle',
                }
            },
            {
                x: selectedXValues,
                y: selectedYValues,
                mode: 'markers',
                type: 'scatter',
                name: 'Deselected Values',
                text: newselectedSampleNames,
                textposition: 'top center',
                marker: {
                    color: '#0000FF',
                    size: 10,
                    symbol: 'diamond',
                }
            },
            {
                x: regression.x,
                y: regression.y,
                mode: 'lines',
                type: 'scatter',
                name: 'Linear Regression',
                marker: {
                    color: '#20B2AA'
                }

            },

        ];

        const layout = {
            title: 'Scatter Plot with Linear Regression',
            xaxis: {
                title: 'Concentration [log10]',
                range: [xAxisRange[0] - xMargin, xAxisRange[1] + xMargin]
            },
            yaxis: {
                title: 'Mean CQ ',
                range: [yAxisRange[0] - yMargin, yAxisRange[1] + yMargin]
            }
        };
        const plotId = 'plotlyDiv_' + dataObj.run_id;

        Plotly.newPlot(plotId, data, layout);

    }


    calculateLinearRegression(xValues: number[], yValues: number[], index, SingleCq, targetName) {
        const n = xValues.length;
        const xSum = xValues.reduce((acc, val) => acc + val, 0);
        const ySum = yValues.reduce((acc, val) => acc + val, 0);
        const xySum = xValues.reduce((acc, val, idx) => acc + val * yValues[idx], 0);
        const xSquaredSum = xValues.reduce((acc, val) => acc + val * val, 0);

        const slope = (n * xySum - xSum * ySum) / (n * xSquaredSum - xSum * xSum);
        const intercept = (ySum - slope * xSum) / n;
        this.graphdata[targetName][index]['calculatedSlope'] = slope;
        this.graphdata[targetName][index]['calculatedIntercept'] = intercept;

        this.calculateCV(SingleCq, slope, intercept, index, targetName);

        const regressionLineX = [Math.min(...xValues), Math.max(...xValues)];
        const regressionLineY = [slope * regressionLineX[0] + intercept, slope * regressionLineX[1] + intercept];

        return { x: regressionLineX, y: regressionLineY };
    }

    calculateCV(SingleCq, slope, intercept, index, targetName) {
        const calc_back_conc = SingleCq.map((item, index) => {
            const cqArray = item.cq;
            const backCalcConc = cqArray.map((y) => {
                if (y === null) return null;
                const bc_log = (y - intercept) / slope;
                return Math.pow(10, bc_log)

            });

            return {
                sample: item.sample,
                bcq: item.bcq,
                cq: item.cq,
                quantity: item.quantity,
                backCalcConc: backCalcConc,
            };
        });

        this.calculateSD(calc_back_conc, index, targetName)
    }

    calculateSD(calcBackConc, index, targetName) {
        this.calc_CV = [];
        this.RE = [];

        calcBackConc.map((res) => {
            const back_calc = res.backCalcConc;
            const Quantity = res.quantity;
            const filteredBackCalc = back_calc.filter(value => value !== null);
            if (filteredBackCalc.length === 0) {
                this.calc_CV.push(null);
                this.RE.push(null);
            } else {

                const meanValue = mean(filteredBackCalc);

                const stdDev = std(filteredBackCalc, 'unbiased');
                const cv = (stdDev / meanValue) * 100;

                this.calc_CV.push(cv);


                this.RE.push(((meanValue - Quantity) / Quantity) * 100);

            }

        })
        this.calc_CV = this.calc_CV.map(num => Number(num?.toFixed(3)));
        this.RE = this.RE.map(num => Number(num?.toFixed(1)));
        this.graphdata[targetName][index]['front_cv'] = this.calc_CV;
        this.graphdata[targetName][index]['front_re'] = this.RE;
    }

    addComment(dataObj, index: number) {
        if (!dataObj.flag[index]) {
            return;
        }

        const comment = dataObj?.comments[index]?.trim() || '';
        const sampleName = dataObj.sample_name[index];
        const idx = this.commentText.findIndex(comment => comment.run_id === dataObj.run_id);

        if (idx !== -1) {
            let sampleIndex = this.commentText[idx].selected_samples.indexOf(sampleName);

            if (sampleIndex !== -1) {
                this.commentText[idx].comments[sampleIndex] = comment;
            } else {
                this.commentText[idx].selected_samples.push(sampleName);
                this.commentText[idx].comments.push(comment);
            }
        }

        else {
            this.commentText.push({ run_id: dataObj.run_id, selected_samples: [sampleName], comments: [comment] });
        }

        this.updateSubmitEnabled();
    }


    isSubmitEnabled(): boolean {
        let hasCheckedCheckbox = false;
        for (let targetName of this.graphdataKeys) {
            for (let dataObj of this.graphdata[targetName]) {
                for (let flag of dataObj.flag) {
                    if (flag) {
                        hasCheckedCheckbox = true;
                        break;
                    }
                }
                if (hasCheckedCheckbox) {
                    break;
                }

            }
            if (!hasCheckedCheckbox) {
                return true;
            }
        }
        for (let targetName of this.graphdataKeys) {
            for (let dataObj of this.graphdata[targetName]) {
                if (dataObj.flag.some((flag, index) => flag && !dataObj.comments[index]?.trim())) {
                    return false; // If any checked checkbox lacks a comment, disable the button
                }

            }
        }
        return true;

    }


    updateSubmitEnabled() {
        this.submitEnabled = this.isSubmitEnabled();
    }

    submitGraphDetails() {
        this.submitResultLoader = true;
        this.submitEnabled = false;
        this.executionId = Math.floor(new Date().getTime() / 1000.0).toString();
        this.searchResult.executionId = this.executionId;
        this.searchResult.commentText = this.commentText;
        this.http.post(this.AnalysisDataURL, this.searchResult).subscribe(
            res => {
                console.log(res);
                window.location.reload();
            },

            error => {
                console.log(error)
            }
        )

    }

    isValidNumber(value: any): boolean {
        return value !== null && value !== undefined && !isNaN(value);
    }

    isAnyCheckboxSelected(dataObj: any): boolean {
        return dataObj.flag?.some((selected: boolean) => selected);
    }

    ngOnDestroy() {
        // Unsubscribe from the observable to avoid memory leaks
        if (this.singleSearchData) {
            this.singleSearchData.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
        if (this.getEventData) {
            this.getEventData.unsubscribe();
        }
        if (this.PostEventData) {
            this.PostEventData.unsubscribe();
        }
    }
}

