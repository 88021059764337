import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-userinfo',
  templateUrl: './edit-userinfo.component.html',
  styleUrls: ['./edit-userinfo.component.scss']
})
export class EditUserinfoComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EditUserinfoComponent>,
    @Inject(MAT_DIALOG_DATA) public formdata: any
  ) { }

  formData = {
    name:[this.formdata.username],
    email: '',
    phoneNumber: ''
  };

  ngOnInit(): void {
    
  }

  submitForm() {
    // Handle form submission logic here
    // For example, you can emit an event or call a service to update the data
    this.dialogRef.close(this.formData);
  }

}
