// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  margin: 1rem;
}

.label {
  font-weight: bold;
  margin-right: 2rem;
}

.mat-form-field.full-width {
  width: 80%;
}

input {
  width: 100%;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.mat-mdc-form-field {
  display: contents;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/template/template.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEE;EACE,iBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,UAAA;AACJ;;AAEE;EACE,WAAA;EACA,sBAAA,EAAA,wDAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA,EAAA,yBAAA;AACJ;;AACE;EACE,iBAAA;AAEJ","sourcesContent":[".container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: start;\r\n    gap: 0.5rem;\r\n    margin: 1rem;\r\n  }\r\n  \r\n  .label {\r\n    font-weight: bold;\r\n    margin-right: 2rem;\r\n  }\r\n  \r\n  .mat-form-field.full-width {\r\n    width: 80%;\r\n  }\r\n  \r\n  input {\r\n    width: 100%;\r\n    box-sizing: border-box; /* Ensure padding and border are included in the width */\r\n  }\r\n\r\n  .spinner-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh; /* Full viewport height */\r\n  }\r\n  .mat-mdc-form-field {\r\n    display: contents;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
